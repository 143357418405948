<template>
  <div>
    <div v-if="selectedKey === 'key_6'">
      <key-common
        :key="selectedKey"
        :status_key="'key_6'"
        date_filter_key="status_date"
        date_filter_name="Assigned Date"
        status_name="PickedUp"
      />
    </div>
    <div v-if="selectedKey === 'key_7'">
      <key-seven :status_key="'key_7'" />
    </div>
    <div v-if="selectedKey === 'key_8'">
      <key-common
        :key="selectedKey"
        :status_key="'key_8'"
        date_filter_key="status_date"
        date_filter_name="Picked Up Date"
        status_name="DispatchToOriginWarehouse"
      />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions,camelcase
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import KeyCommon from '../../components/status-flow/KeyCommon.vue'
import KeySeven from '../../components/status-flow/KeySeven.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    KeyCommon,
    KeySeven,
  },
  data() {
    return {
      selectedKey: '',
    }
  },
  mounted() {
    this.getKey()
  },
  methods: {
    getKey() {
      this.selectedKey = this.$route.params.key
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';
</style>
